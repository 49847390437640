<template>
<div class="row">
  <div class="col-12" v-if="partner">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="form__label">Date Issued</label>
          <input :value="partner.date_release" type="date" class="form-control form__input margin-bottom" disabled>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="form__label">Bank account number</label>
          <input :value="partner.banknubanno" type="text" class="form-control form__input margin-bottom"  disabled>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label  class="form__label">Date Release</label>
          <input :value="partner.date_expiry" type="date" class="form-control form__input margin-bottom" disabled>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="form__label">Bank account number</label>
          <input :value="partner.banknubanno" type="text" class="form-control form__input margin-bottom"  disabled>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="form__label">Bvn number</label>
          <input :value="partner.bankbvnno" type="text" class="form-control form__input margin-bottom"  disabled>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group ">
          <label class="form__label">Bank name</label>
          <select class="form-control form__input margin-bottom" disabled>
            <option >{{ partner.document_client_id }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="form__label">Bank Type</label>
          <select class="form-control form__input margin-bottom" disabled>
            <option >{{ partner.banktype }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "BankCrazies",
  computed: {
    ...mapGetters({
      partner: 'auth/partner'
    })
  }
}
</script>

<style scoped>

</style>