<template>
  <div class="footers">
  </div>
</template>

<script>
    export default {
      name: "Footers",
      data() {
        return {
        }
      }
    }
</script>

<style scoped>

</style>
