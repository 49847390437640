<template>
    <div class="page-wrapper chiller-theme toggled">
        <a id="show-sidebar" class="btn btn-sm btn-dark" href="#">
            <i class="fas fa-bars"></i>
        </a>
        <side-bar> </side-bar>
        <!-- sidebar-wrapper  -->
        <main class="page-content">
            <div class="container-fluid" style="padding-bottom: 0; margin-bottom: 0">
              <div class="check-position">
                <div class="body-contents">
                  <div class="row">
<!--                    <div class="col-md-12">-->
<!--                      <div class="top-nav ml-3">-->
<!--                        <ul class="top-navs">-->
<!--                          <li class="search__container">-->
<!--&lt;!&ndash;                            <input class="search__input" type="text" placeholder="Search for loans">&ndash;&gt;-->
<!--                          </li>-->
<!--                          <li class="push-end">-->
<!--                            <a href="#">-->
<!--                              <i class="fa fa-cog"></i>-->
<!--                            </a>-->
<!--                          </li>-->
<!--                          <li class="push-end">-->
<!--                            <a href="#">-->
<!--                              <img src="/images/notification.svg" alt="Notification">-->
<!--                            </a>-->
<!--                          </li>-->
<!--                          <li class="avatar">-->
<!--                            <a href="#">-->
<!--                              <img src="/images/avatar.jpg" alt="Avatar">-->
<!--                            </a>-->
<!--                          </li>-->
<!--                        </ul>-->
<!--                        <div class="top-navs-mobile">-->
<!--                          <ul class="top-mobile">-->
<!--                            <li class="logo-image">-->
<!--                              <div class="image-holder">-->
<!--                                <img src="/images/logo.svg" alt="logo">-->
<!--                              </div>-->
<!--                            </li>-->
<!--                            <li class="push-end">-->
<!--                              <a href="#">-->
<!--                                <i class="fa fa-cog"></i>-->
<!--                              </a>-->
<!--                            </li>-->
<!--                            <li class="push-end">-->
<!--                              <a href="#">-->
<!--                                <img src="/images/notification.svg" alt="Notification">-->
<!--                              </a>-->
<!--                            </li>-->
<!--                            <li class="avatar">-->
<!--                              <a href="#">-->
<!--                                <img src="/images/avatar.jpg" alt="Avatar">-->
<!--                              </a>-->
<!--                            </li>-->
<!--                            <li class="display-none">-->
<!--                                    <span class="close-sidebar" @click.prevent="OpenMenu" style="cursor: pointer">-->
<!--                                        <i class="material-icons" v-if="!isToggle">menu</i>-->
<!--                                        <i class="material-icons" v-if="isToggle">clear</i>-->
<!--                                    </span>-->
<!--                            </li>-->
<!--                          </ul>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
                  </div>
                  <div class="row" >
                    <div class="col-md-12">
                      <slot name="admin" />
                    </div>
                  </div>
                </div>
                <div class="footer-contents">
                  <div class="">
                    <footers ></footers>
                  </div>
                </div>
              </div>
            </div>
        </main>
        <!-- page-content" -->
    </div>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'
import SideBar from "@/components/navigate/SideBar";
import Footers from "./Footers";
export default {
  name: "AdminDefault",
  components: {Footers, SideBar},
  methods: {
    ...mapActions({
      GetAllLoans: 'loan/GetAllLoans',
      PartnerSelections: 'auth/PartnerSelections',
      LoanApplication: 'loan/LoanApplication'
    }),
    ...mapMutations({
      Toggles: 'setup/isToggle'
    }),
    OpenMenu () {
      if (this.isToggle) {
        this.Toggles(false)
      } else {
        this.Toggles(true)
      }
    },
    async LoadGetAllLoans() {
      await this.GetAllLoans(this.user.partner_id[0])
    },
    async LoadLoanApplication() {
      await this.LoanApplication(this.user.partner_id[0])
    },
    async LoadPartnerSelections() {
      await this.PartnerSelections(this.user.partner_id[0])
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isToggle: 'setup/isToggle'
    })
  },
  created() {
    this.LoadPartnerSelections()
    this.LoadGetAllLoans()
    this.LoadLoanApplication()
  }
}
</script>

<style scoped>

</style>
