import { render, staticRenderFns } from "./AdminDefault.vue?vue&type=template&id=1f54bff2&scoped=true&"
import script from "./AdminDefault.vue?vue&type=script&lang=js&"
export * from "./AdminDefault.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f54bff2",
  null
  
)

export default component.exports