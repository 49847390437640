<template>
  <div class="form-group">
    <label :for="name" class="form__label">{{ label }}</label>
    <currency-input v-model="innerValue"
                    :name="name"
                    v-bind="$attrs"
                    :placeholder="placeholder"
                    autocomplete="off"
                    class="form-control form__input form__input--small"
                    />
  </div>
</template>

<script>

export default {
  name: "CurrencyDateInput",
  data: function () {
    return {
      innerValue: 0,
    }
  },
  props: {
    vid: {
      type: String,
    },
    type: {
      type: [Number, String],
    },

    placeholder: {
      type: String,
    },

    label: {
      type: String,
    },

    classes: {
      type: String,
    },


    name: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    // must be included in props
    value: {
      type: [String, Number],
    },
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
}
</script>

<style scoped>

</style>
