<template>
<div class="row">
  <div class="col-md-12" v-if="partner">
    <div class="section">
      <div class="row pt-4">
        <div class="col-md-6">
          <div class="form-group">
            <label class="form__label">Corperate/Group</label>
            <select class="form-control form__input margin-bottom" disabled>
              <option >{{ partner.industry_group }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label  class="form__label">Date</label>
            <input :value="partner.write_date" type="date"
                   class="form-control form__input margin-bottom"
                   disabled>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label  class="form__label">Date of Birth</label>
            <input :value="partner.birthday" type="date"
                   class="form-control form__input margin-bottom"
                   disabled>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form__label">Birth Country</label>
            <select class="form-control form__input margin-bottom" disabled>
              <option >Nigeria</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form__label">State, City/Regions</label>
            <select class="form-control form__input margin-bottom" disabled>
              <option >Lagos</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form__label">Marital Status</label>
            <select class="form-control form__input margin-bottom" disabled>
              <option >{{ partner.maritalstatus }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form__label">Gender</label>
            <select class="form-control form__input margin-bottom" disabled>
              <option >{{ partner.gender }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form__label">Select Country Code</label>
            <select class="form-control form__input margin-bottom" disabled>
              <option >+234</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label  class="form__label">Phone number</label>
            <input :value="partner.mobile" type="date"
                   class="form-control form__input margin-bottom"
                   disabled>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label  class="form__label">Means of Identification</label>
            <input :value="partner.document_client_id" type="date"
                   class="form-control form__input margin-bottom"
                   disabled>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <h2>ID Card No:</h2>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label  class="form__label">Issuers Date</label>
            <input :value="partner.date_expiry" type="date"
                   class="form-control form__input margin-bottom"
                   disabled>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label  class="form__label">Expiration Date</label>
            <input :value="partner.date_release" type="date"
                   class="form-control form__input margin-bottom"
                   disabled>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form__label">Country of Issuance</label>
            <select class="form-control form__input margin-bottom" disabled>
              <option >Nigeria</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form__label">State of Issuance</label>
            <select class="form-control form__input margin-bottom" disabled>
              <option >Lagos</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label  class="form__label">Email Address</label>
            <input :value="partner.email" type="email"
                   class="form-control form__input margin-bottom"
                   disabled>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <h2>Next of Kin</h2>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label  class="form__label">Surename</label>
            <input :value="partner.surename" type="text"
                   class="form-control form__input margin-bottom"
                   disabled>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label  class="form__label">First name</label>
            <input :value="partner.lastname" type="text"
                   class="form-control form__input margin-bottom"
                   disabled>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label  class="form__label">Phone number</label>
            <input :value="partner.phone" type="text"
                   class="form-control form__input margin-bottom"
                   disabled>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "PersonalizeDetail",
  computed: {
    ...mapGetters({
      partner: 'auth/partner'
    })
  }
}
</script>

<style scoped>

</style>