<template>
  <div class="form-group">
    <label :for="name" class="form__label">{{ label }}</label>
    <input :type="type" :id="name" :name="name"
           :placeholder="placeholder" v-model="innerValue"
           autocomplete="off"
           class="form-control form__input margin-bottom"
    >
  </div>

</template>

<script>
export default {
  name: "InputForms",
  props: {
    vid: {
      type: String,
    },
    type: {
      type: String,
    },

    placeholder: {
      type: String,
    },

    label: {
      type: String,
    },

    classes: {
      type: String,
    },


    name: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    // must be included in props
    value: {
      type: null,
    },
  },
  data: () => ({
    innerValue: ''
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  computed: {
    image () {
      if (this.type === 'password') {
        return '/Assets/eye.svg'
      }
      return null
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
}
</script>

<style scoped>

</style>