<template>
  <admin-default v-slot:admin>
    <div class="application" >
      <div class="container container-resize">
        <div class="row border-bottom pb-5 pl-4">
          <div class="col-md-9"><h1 class="application__secondary--title">Loan Application</h1></div>
          <div class="col-6 col-md-3">
            <h1 class="application__main--title">Welcome,</h1>
            <h1 class="application__tertiary--title">{{ user.name }}</h1>
          </div>
        </div>
      </div>
      <!--NAV-->
      <div class="containe gcontainer-resize" v-if="partner !== null">
        <application-tabs ></application-tabs>
      </div>
    </div>
  </admin-default>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import AdminDefault from "../navigate/AdminDefault";
import ApplicationTabs from "../forms/ApplicationTabs";
export default {
  name: "LoanApplicationForms",
  components: {ApplicationTabs, AdminDefault},
  methods: {
    ...mapActions({}),
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      partner: 'auth/partner',
      application: 'loan/loan_application'
    })
  },
}
</script>

<style scoped>

</style>
