<template>
  <div class="tab-navigation">
    <b-tabs content-class="mt-3" justified v-model="tabIndex">
      <div class="container-reset">
        <b-tab title="Personal Details" active>
          <personalize-detail v-if="partner"></personalize-detail>
<!--          <personal-details v-if="partner !==null" :partner="partner"></personal-details>-->
        </b-tab>
<!--        <b-tab title="Employment Details">-->
<!--          <employee-details v-if="partner !==null"></employee-details>-->
<!--        </b-tab>-->
        <b-tab title="Disbursement Details">
          <bank-crazies v-if="partner"></bank-crazies>
        </b-tab>
        <b-tab title="Apply For Loan">
          <div class="row">
            <div class="col-md-6">
              <currency-date-input placeholder="Amount to loan"
                                   label="Amount request"
                                   name="loan_credit"
                                   v-model.trim="application.loan_credit"></currency-date-input>
            </div>
            <div class="col-md-6">
              <input-forms placeholder="Your monthly income"
                           label="Monthly income"
                           name="loan_duration"
                           v-model.trim="application.loan_duration"></input-forms>
            </div>
          </div>
        </b-tab>
        <div class="tabs-buttons mb-5">
          <div>
            <button type="button" class="btn button button-cta cta mr-1"
                    v-if="tabIndex < 2"
                    @click="SaveLoan">Save</button>
            <button type="button" class="btn button button-cta cta ml-1" @click="ContinueLoan">
              <span v-if="3 > tabIndex">Continue</span>
              <span v-if="tabIndex === 3">Submit</span>
            </button>
          </div>
          <div v-if="tabIndex > 0">
            <button type="button" class="btn button btn-outline-primary button-outline cta" @click="BackLoan">Back</button>
          </div>
        </div>
      </div>
    </b-tabs>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapGetters} from 'vuex'
// import PersonalDetails from "../home/pages/post/PersonalDetails";
// import BankDetails from "../home/pages/post/BankDetails";
// import EmployeeDetails from "../home/pages/post/EmployeeDetails";
import CurrencyDateInput from "./CurrencyDateInput";
import InputForms from "./InputForms";
import BankCrazies from "../home/pages/prons/BankCrazies";
import PersonalizeDetail from "../home/pages/prons/PersonalizeDetail";
export default {
  name: "ApplicationTabs",
  data () {
    return {
      application: {},
      tabIndex: 1
    }
  },
  components: {PersonalizeDetail, BankCrazies, InputForms, CurrencyDateInput},
  methods: {
    ...mapMutations({
      Applied: 'setup/apply'
    }),
    ...mapActions({
      ApplicationId: 'loan/ApplicationId',
    }),
    SaveLoan () {
      // this.tabIndex++
    },
    async ContinueLoan () {
      let vm = this
      if (vm.tabIndex === 2){
        if (!this.application.loan_credit || !this.application.loan_duration) {
          this.$toastr.w('Enter amount, and duration of loans')
          return false
        }

        try {
          let formData = new FormData
          formData.append('national_id_type', this.partner.national_id_type)
          formData.append('citizenship', this.partner.citizenship)
          formData.append('user_authorize_id', this.partner.user_id[0])
          formData.append('industry_sector', this.partner.industry_sector)
          if (this.partner.maritalstatus) {
            formData.append('mstatus', this.partner.maritalstatus)
          } else {
            formData.append('mstatus', 'Single')
          }
          formData.append('date_release', this.partner.date_release)
          formData.append('municipality', this.partner.municipality)
          formData.append('street',this.partner.street)
          formData.append('loan_duration', this.application.loan_duration)
          formData.append('authorize_date', new Date().toISOString().slice(0, 10))
          formData.append('sub_branch_id', this.partner.sub_branch_id[0])
          formData.append('partner_id', this.partner.id)
          formData.append('branch_id', this.partner.branch_id[0])
          formData.append('country_id', this.partner.country_id[0])
          formData.append('date_expiry', this.partner.date_expiry)
          formData.append('state', 'draft')
          formData.append('document_client_id', this.partner.document_client_id)
          formData.append('loan_credit',this.application.loan_credit)
          formData.append('country_issuance', this.partner.country_issuance[0])
          formData.append('data_placement', new Date().toISOString().slice(0, 10))
          formData.append('is_bank_customer', this.partner.is_bank_customer)
          formData.append('is_company_customer', this.partner.is_company_customer)
          formData.append('customer_type_name', this.partner.customer_type_name)
          formData.append('name', this.partner.name)
          formData.append('industry_group', this.partner.industry_group)
          formData.append('gender', this.partner.gender)
          formData.append('ssituation', 'retiree')
          formData.append('district_id', this.partner.district_id)
          formData.append('mobile', this.partner.mobile)
          formData.append("officer", this.partner.refname4[0])
          const response = await this.ApplicationId(formData)
          if (response.status) {
            this.$toastr.s(this.message)
            this.$router.push('/loan-application')
          }
        } catch (e) {
          this.$toastr.e(this.errors)
        }
      }
      vm.tabIndex++
    },
    BackLoan () {
      this.tabIndex--
    }
  },
  watch: {
    tabIndex (value) {
      if (value === 2) {
        this.Applied(value)
      }
    }
  },
  computed: {
    ...mapGetters({
      partner: 'auth/partner',
      errors: 'setup/errors',
      message: 'setup/message',
    })
  }
}
</script>

<style scoped>

</style>
