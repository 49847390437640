<template>
    <nav id="sidebar" class="sidebar-wrapper" :class="{'close-sidebars': !isToggle, 'open-sidebars': isToggle}">
        <div class="sidebar-content">
            <div class="sidebar-header">
                <img class="img-responsive " src="/images/logo.svg"
                     alt="Logo">
            </div>
            <div class="sidebar-menu" style="font-size: 14px;">
                <ul>
                    <li class="header-menu">
                        <span>Dashboard</span>
                    </li>

                    <li >
                        <router-link to="/dashboard" @click.native="CloseSideBar">
                            <img src="/images/logo/dashboards.svg" alt="">
                            <span>Dashboard</span>
                        </router-link>
                    </li>
                    <li >
                        <router-link to="/loan" @click.native="CloseSideBar">
                            <img src="/images/logo/card.svg" alt="">
                            <span>Pay Loan</span>
                        </router-link>
                    </li>
                    <li >
                        <router-link to="/account-report" @click.native="CloseSideBar">
                            <img src="/images/logo/pluss.svg" alt="">
                            <span>Loan Account Statement</span>
                        </router-link>
                    </li>
                    <li >
                        <router-link to="/schedule-report" @click.native="CloseSideBar">
                            <img src="/images/logo/pluss.svg" alt="">
                            <span>Loan Schedule Report</span>
                        </router-link>
                    </li>
                    <li >
                        <router-link to="/calculators-report" @click.native="CloseSideBar">
                            <img src="/images/logo/calculator.svg" alt="">
                            <span>Loan Calculator</span>
                        </router-link>
                    </li>
                    <li >
                        <router-link to="/loan-application" @click.native="CloseSideBar">
                            <img src="/images/logo/loan.svg" alt="">
                            <span>Loan Application</span>
                        </router-link>
                    </li>
                    <li v-if="hideLi">
                        <router-link to="/credentials" @click.native="CloseSideBar">
                            <img src="/images/logo/clouds.svg" alt="">
                            <span>Download Guarantors Form</span>
                        </router-link>
                    </li>
                    <li v-if="hideLi">
                        <router-link to="" @click.native="CloseSideBar">
                            <img src="/images/logo/wpforms.svg" alt="">
                            <span>Submit Documentations</span>
                        </router-link>
                    </li>
<!--                    <li >-->
<!--                        <router-link to="/profile" @click.native="CloseSideBar">-->
<!--                            <img src="/images/logo/user.svg" alt="">-->
<!--                            <span>Profile</span>-->
<!--                        </router-link>-->
<!--                    </li>-->
                    <li >
                        <a href="#" @click.prevent="LoggedOutUser">
                            <img src="/images/logo/logout.svg" alt="">
                            <span>Sign Out</span>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- sidebar-menu  -->
        </div>
        <!-- sidebar-content  -->
        <div class="sidebar-footer">
            <a href="#">
                <i class="fa fa-cog"></i>
                <span class="badge-sonar"></span>
            </a>
            <a href="#">
                <i class="fa fa-power-off"></i>
            </a>
        </div>
    </nav>
</template>

<script>
    import { mapMutations, mapGetters, mapActions } from 'vuex'
    export default {
        name: "SideBar",
        data () {
          return {
            hideLi: false
          }
        },
        mounted () {
            /* global $ */

            $(".sidebar-dropdown > a").click(function() {
                $(".sidebar-submenu").slideUp(200);
                if (
                    $(this)
                        .parent()
                        .hasClass("active")
                ) {
                    $(".sidebar-dropdown").removeClass("active");
                    $(this)
                        .parent()
                        .removeClass("active");
                } else {
                    $(".sidebar-dropdown").removeClass("active");
                    $(this)
                        .next(".sidebar-submenu")
                        .slideDown(200);
                    $(this)
                        .parent()
                        .addClass("active");
                }
            });

            $("#close-sidebar").click(function() {
                $(".page-wrapper").removeClass("toggled");
            });
            $("#show-sidebar").click(function() {
                $(".page-wrapper").addClass("toggled");
            });
        },
        computed: {
            ...mapGetters({
                isToggle: 'setup/isToggle'
            })
        },
        methods: {
          ...mapMutations({
            Toggles: 'setup/isToggle',
          }),
          ...mapActions({
            LoggedOut: 'auth/Logout'
          }),
          CloseSideBar () {
            if (this.isToggle) {
              this.Toggles(false)
            } else {
              this.Toggles(true)
            }
          },
          RemoveDashboard () {
              if (this.isToggle) {
                  this.Toggles(false)
              } else {
                  this.Toggles(true)
              }
          },
          async LoggedOutUser () {
            await this.LoggedOut()
            this.$router.push('/')
          }
        }
    }
</script>

<style scoped>

</style>
